<template>
  <button
    class="btn btn-outline-light btn-sm"
    :title="$t(`invoice.downloadInvoice`)"
    v-on:click="show"
    v-if="value > 0"
  >
    <BaseIcon name="invoice-download" color="var(--black)" />
  </button>
  <InvoiceModal ref="modal" v-model="value"  v-if="value > 0" />

</template>
<script>
import { defineAsyncComponent } from "vue";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));
const InvoiceModal = defineAsyncComponent(() => import("../../modals/InvoiceModal.vue"));

export default {
  name: "Invoice",
  components: { InvoiceModal, BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    show() {
      this.$refs.modal.showModal();
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
